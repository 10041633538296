* {
  padding: 0;
  margin: 0;
}

.message {
  padding: 30px;
  background-color: rgb(48, 153, 48);
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.container {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 30px;
}

label {
  font-size: 12px;
}

input {
  padding: 10px;
  font-size: 16px;
}

button {
  padding: 20px;
  cursor: pointer;
  background-color: rgb(255, 91, 36);
  border: none;
  font-size: 18px;
  font-weight: 700;
  margin-top: 30px;
  color: #fff;
  width: 100%;
}

.products {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding: 80px;
}

.products .product {
  padding: 15px;
  border: 1px solid #999;
}

.products .product > p {
  display: flex;
  justify-content: space-between;
}

.products .product > p:not(:first-child) {
  font-size: 14px;
  margin-top: 5px;
}

.products .product .items {
  display: flex;
  gap: 15px;
  margin-top: 15px;
}

.products .product .items span {
  padding: 5px;
  min-width: 30px;
  border: 1px solid #000;
  text-align: center;
  cursor: pointer;
}

.products .product .items span.unavailable {
  cursor: default;
  border-color: #999;
  color: #999;
}

.products .product .items span.selectied {
  background-color: #000;
  color: #fff;
}

.checkout {
  background-color: #f2f2f2;
  padding: 80px;
}

.checkout > p {
  display: flex;
  justify-content: space-between;
  line-height: 1.5;
}

.checkout > p span {
  font-weight: bold;
}

.checkout .payment {
  margin-top: 30px;
}

.checkout .payment > div {
  display: flex;
  gap: 15px;
  justify-content: space-between;
}

.checkout .methods {
  margin-top: 30px;
  display: flex;
  gap: 15px;
  justify-content: space-between;
}

.checkout .methods p {
  padding: 10px 20px;
  border: 1px solid #999;
  cursor: pointer;
}

.checkout .methods p.selected {
  background-color: #000;
  border-color: #000;
  color: #fff;
}